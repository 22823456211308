#main {
  .nav {
    width: 300px;
    .logo {
      height: 56px;
      margin: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      .left {
        height: 42px;
      }
      .right {
        width: 140px;
      }
    }
  }

  .site {
    .header1 {
      height: 64px;
      background-color: #fff;
      padding: 0px 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .trigger {
        font-size: 18px;
        line-height: 64px;
        cursor: pointer;
        transition: color 0.3s;
      }
    .trigger:hover {
        color: #1890ff;
    }
    }
    .content1 {
      min-height: calc(~'100vh - 112px');
      padding: 16px;
    }
    .footer1 {
      height: 48px;
      color: #888;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
em {
  background-color: yellow;
}


#loginSection {
  .inner {
      display: flex;
      align-items: center;
      img {
          height: 32px;
          margin: 0 8px;
      }
  }
}

.ant-modal-confirm-body-wrapper {
  padding: 32px;
}
.zkcyCard {
  .ant-row.ant-form-item{
    margin: 0;
  } 
}




@primary-color: #1DA57A;