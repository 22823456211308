.link-button{
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    color: #1890ff;
    :hover {
        background:#e6f7ff;
        color:red;
    }
}
@primary-color: #1DA57A;