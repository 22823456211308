.zkcy {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .bg {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .center {
        width: 1200px;
    }
    .footer {
        // background-image: url("http://wits.zkcyxx.com/zkcy/images/share/xkpt/footer_background.png");
        // background-size: 100% 100%;
        // background-color: rgba(0,0,0,0.8);
        .center {
            color: #555;
            height: 64px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .banner {
        .lbt {
            height: 280px;
            background-size: 100% 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            .text {
                display: flex;
                align-items: center;
                width: 100%;
                height: 64px;
                color: white;
                background-color: rgba(0, 0, 0, 0.45);
                p {
                    padding: 0 32px;
                    font-size: 14px;
                    line-height: 16px;
                }
            }
        }
    }

    .subtitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 36px;
        line-height: 36px;
        font-size: 18px;
        color: #555;
        padding: 0 0 0 16px;
        margin: 4px 0;
        border-left: 4px solid #1DA57A;
        border-bottom: 1px solid #d7d7d7;
    }

    .achieveTabs{
        .ant-tabs-tab{
          display: flex;
          justify-content: center;
          margin: 4px 0;
          height: 36px;
          width: 160px;
          padding: 8px;
        }
        .ant-tabs-tab:hover  {
          background:rgba(29,165,122,.5);
          color:white;
        }
        .ant-tabs-tab.ant-tabs-tab-active{
          background:#1DA57A;
        }
        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
          color:white;
          // background: yellow;
        }
        .ant-tabs-ink-bar{
          width: 0;
          height: 0;
        }
        .ant-tabs {
          width:100%;
        }
      }

    .ant-carousel {
        width: 100%;
    
        .slick-dots-bottom {
            height: 24px;
        }
        .slick-dots {
            li button{
                background-color:#D4D4D4;
                width: 10px;
                height: 10px;
                margin: 0 3px;
                border-radius: 50%;
            } 
            li.slick-active button{
                background-color: #1DA57A;
                width: 10px;
                height: 10px;
                margin: 0 3px;
                border-radius: 50%;
            }
        }
        .slick-track {
            width: 100%;
        }
    }

    .elisp2 {
        position: relative;
        line-height: 24px;
        height: 48px;
        overflow: hidden;
    }
    
    .elisp2noie {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
    
    .elisp1noie {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    @media screen and (max-width: 1200px) {
        .center {
            width: 100%;
        }
    }

}



@primary-color: #1DA57A;